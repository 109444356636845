import { ApiRequestType } from "./api"

export const ocr = {
    imageAutoOcr: {
        api: "/kyc/ocr/auto",
        method: "post" as ApiRequestType,
    },
    aadharMasking: {
        api: '/masking',
        method: "post" as ApiRequestType
    },
    voterId: {
        api: '/kyc/voterid/upload',
        method: 'post' as ApiRequestType
    }
}

export const faceApi = {
    faceMatch: {
        api: '/facematch',
        method: "post" as ApiRequestType,
        
    },
    faceAnalyse: {
        api: '/faceanalyse',
        method: "post" as ApiRequestType,
    }
}

export const loginApi = {
    login: {
        api: '/auth/user/login',
        method: "post" as ApiRequestType,
        baseurl : 'baseurl2',
    }
}

export const signupApi = {
    login: {
        api: '/auth/user/signup',
        method: "post" as ApiRequestType,
        baseurl : 'baseurl2',
    }
}

export const adminUser = {
    user:{
        api:'/console/admin/users',
        method:"get" as ApiRequestType,
        baseurl:'baseurl2',
        isBearer: true,
    }
}

export const adminSearch = {
    search:{
        url:'/console/admin/users/search',
        method:"get" as ApiRequestType,
        baseurl:'baseurl2',
        isBearer: true,
        param:'',
        get api() {
            return this.url + '?_q=' + this.param
        }
    }
}

export const UserProduct = {
    userdetail: {
        api: '/console/admin/users/get',
        method: "post" as ApiRequestType,
        baseurl : 'baseurl2',
        isBearer: true,
    }
}

export const allProducts = {
    products: {
        api: '/product/products',
        method: "get" as ApiRequestType,
        baseurl : 'baseurl2',
    }
}

export const addressApi = {
    addressMatch: {
        api: '/address_match',
        method: 'post' as ApiRequestType,
        baseurl: 'addressmatch'
    },
    addressMatchDetail:{
        api: '/match/address/breakdown',
        method: 'post' as ApiRequestType,
    }
}

export const violencDetectioneApi = {
    violencDetectione: {
        api: '/dev/detectnsfw',
        method: 'post' as ApiRequestType,
        baseurl:'violence'
    },
    violencDetectioneVideo: {
        api: '/status',
        method: 'post' as ApiRequestType,
        baseurl:'violence-video'
    },

}

export const enquiriesApi = {
    enquiries: {
        api: '/console/admin/enquiries',
        method: 'post' as ApiRequestType,
        baseurl:'baseurl2',
        isBearer: true,
    },
}

export const settingsApi = {
    settings: {
        api: '/user/settings',
        method: 'get' as ApiRequestType,
        baseurl:'baseurl2',
        isBearer: true,
    },
    settingsUpdate: {
        api: '/user/settings',
        method: 'post' as ApiRequestType,
        baseurl:'baseurl2',
        isBearer: true,
    },
}

export const selfieMatchApi = {
    selfie: {
        api: '/fs/selfie',
        method: 'post' as ApiRequestType,
        baseurl:'selfie',
    },
    selfieDelete: {
        api: '/fs/selfie/:id',
        method: 'delete' as ApiRequestType,
        baseurl:'selfie',
    }
} 

export const UserPermission = {
    userpermission: {
        api: '/console/admin/users/update-permissions',
        method: "post" as ApiRequestType,
        baseurl : 'baseurl2',
        isBearer: true,
    }
}

export const NSFW = {
    nsfwApi: {
        api: '/nsfw/status',
        method: "post" as ApiRequestType,
        baseurl: 'selfie'
    }
}

export const SpamClassificationApi = {
    spamclassification: {
        api: '/spam',
        method: "post" as ApiRequestType,
        baseurl : 'selfie',
    }
}

export const docQualityCheckAPi = {
    docQualityCheck: {
        api: '/doc-quality',
        method: "post" as ApiRequestType,
    }
}

export const CKYCCropping = {
    ckycCropping: {
        api: '/cropping',
        method: "post" as ApiRequestType,
        baseurl: 'baseurl',
    }
}