import { Grid, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Loader from "../components/Common/Loader/Loader";
import { OutletcontextType } from "../components/Layout/Index";
import FeatureFrame from "../components/Common/FeatureFrame";
import LiveIcon from "../Icons/LiveIcon";
import VideoCameraIcon from "../Icons/VideoCameraIcon";
import MapIcon from "../Icons/MapIcon";
import CustomButton from "../components/Common/CustomButton";
import ArrowLeftIcon from "../Icons/ArrowLeftIcon";
import Ckyc1 from "../Icons/Ckyc1";
import Ckyc2 from "../Icons/Ckyc2";
import Ckyc3 from "../Icons/Ckyc3";
import FileDragDrop from "../components/Common/FileDragDrop";
import HeaderTop from "../components/pages/OCR/HeaderTop";
import FilePreview from "../components/pages/FaceMatch/FilePreview";
import { ckycCropperApi } from "../service/apiActions/FaceAction";
import axios from "axios";

const VideoKyc = () => {
  const { handleChangeTitle, showSnackbar } =
    useOutletContext<OutletcontextType>();
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState<any>({});
  const [result, setResult] = useState<any>({});
  const [fileSize, setFileSize] = useState<any>(0);

  useEffect(() => {
    document.title = "AiFise |  CKYC";
    handleChangeTitle(
      "CKYC",
      "Make Customer Onboarding a Comfortable Experience in Their Homes."
    );
  }, []);

  const handleFileChange = async (files: any) => {
    // setFiles(files[0]);
    console.log(files[0]);
    if (files.length > 0 && files[0].size > 1024 * 1024 * 3) {
      showSnackbar("File size cannot be greater than 3 MB");
      return;
    } else if (
      files.length > 0 &&
      !["application/pdf", "image/tiff", "image/tif"].includes(files[0].type) // Accepts pdf, tiff file
    ) {
      showSnackbar("Only pdf, tiff, tif files are allowed to upload");
      return;
    }
    setFiles(files[0]);
    const sizeInMB = (files[0].size / (1024 * 1024)).toFixed(2);
    setFileSize(sizeInMB + " MB");
    setLoading(true);
    let _formdata = new FormData();
    _formdata.append("file", files[0]);

    const auth = localStorage.getItem("userLoginData")
      ? JSON.parse(localStorage.getItem("userLoginData") as string)
      : null;

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token") as string)
      : null;

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cropping`, _formdata, {
        headers: {
          "Content-Type": "application/zip",
          Authorization:
            auth?.token !== undefined
              ? `Bearer ${auth?.token}`
              : `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      })
      .then((res: any) => {
        setLoading(false);
        setResult(res);
        console.log("111res", res);
        // Create a Blob from the response
        const zipBlob = new Blob([res.data], { type: "application/zip" });

        console.log("zipBlob", zipBlob);

        // Create a temporary URL for the Blob
        const downloadUrl = window.URL.createObjectURL(zipBlob);

        // Create an anchor element and trigger the download
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "downloaded-file.zip"; // Customize filename if needed
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        setLoading(false);
        showSnackbar(
          error?.message ||
            "Something went wrong, please try again sometime later"
        );
      });
  };

  const handleReset = () => {
    setResult({});
    setFiles({});
    setFileSize({});
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {Object.keys(result).length > 0 ? (
        <>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} lg={8}>
              <HeaderTop reset={handleReset} />
            </Grid>
          </Grid>
          <Box>
            <Grid container spacing={2} mt={0} justifyContent="center">
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <FilePreview
                  sx={{ mt: 1 }}
                  name={files?.name}
                  filesize={fileSize}
                  handleClose={() => {
                    setResult({});
                    setFiles({});
                    setFileSize({});
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={6} lg={5}>
            <FileDragDrop isPDF handleChange={handleFileChange} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" align="center">
              <b>Note:</b> File size should be less than 3 MB and only pdf,
              tiff,tif are allowed
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* <div style={{ paddingTop: "24px" }}>
        <CustomButton
          onClick={() => {
            window.open(" https://main.dgsbgrcxqrjjv.amplifyapp.com/");
          }}
          sx={{
            display: "flex",
            padding: "10px 10px 10px 26px",
            alignItems: "center",
            gap: "16px",
            borderRadius: "200px",
            border: "1px solid #0D93F0",
            background: "#0D93F0",
            margin: "auto",
          }}
        >
          <div
            style={{
              color: "#FFF",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "350",
              lineHeight: "28px",
            }}
          >
            See it in Action
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
              gap: "10px",
              borderRadius: "200px",
              background: "#FFFF",
            }}
          >
            <ArrowLeftIcon />
          </div>
        </CustomButton>
      </div> */}

      <FeatureFrame
        frameTitle="Compliance Excellence"
        frameDescription="Unveiling Vital CKYC Benefits"
        icon1={<Ckyc1 />}
        icon2={<Ckyc2 />}
        icon3={<Ckyc3 />}
        iconDescription1="Effortlessly thwart fraud by consolidating customer information into a centralized system."
        iconDescription2="Simplify KYC hassle by reducing information submissions—streamlined, secure, and user-friendly."
        iconDescription3="Assure quick access to verified KYC documents, cutting through the delays of traditional authentication."
        iconTitle1="Centralized System"
        iconTitle2="Streamlined KYC"
        iconTitle3="Quick Access"
      />
      <style>{`.css-3ef1jy-MuiGrid-root{  padding-top: 0px; }`}</style>
    </>
  );
};

export default VideoKyc;
