import { Box, Grid } from "@mui/material";
import ImageOCRIcon from "../Icons/ImageOCRIcon";
import FaceMatchIcon from "../Icons/FaceMatchIcon";
import FaceAnalysisIcon from "../Icons/FaceAnalysisIcon";
import OtherIcon from "../Icons/OtherIcon";
import VoterIdIcon from "../Icons/VoterIdIcon";
import VideoKycIcon from "../Icons/VideoKycIcon";
import CKYCIcon from "../Icons/CKYCIcon";
import GistIcon from "../Icons/GistIcon";
import { ProductCard } from "../components/pages/Homepage";
import AadharMaskingIcon from "../Icons/AadharMaskingIcon";
import Heading from "../components/pages/Homepage/Heading";
import {
  allProductsApi,
  userSettingApi,
} from "../service/apiActions/FaceAction";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// const data = [
//     {
//         icon: ImageOCRIcon,
//         title: "Image OCR",
//         link: '/dashboard/ocr',
//         isExternal: false,
//         description: "Easily retrieve information from an image of Aadhar card, Driver's license, Voter ID, or PAN card."
//     }, {
//         icon: FaceMatchIcon,
//         title: "Face Match",
//         link: '/dashboard/face-match',
//         isExternal: false,
//         description: "Analyze faces from two different sources and produce a similarity score.",
//     }, {
//         icon: FaceAnalysisIcon,
//         title: "Face Analysis",
//         link: '/dashboard/face-analysis',
//         isExternal: false,
//         description: "Determine the liveness, degree of blurriness, presence of objects, and count of faces in a selfie.",
//     }, {
//         icon: AadharMaskingIcon,
//         title: "Aadhar Masking",
//         link: '/dashboard/aadhar-masking',
//         isExternal: false,
//         description: "Precisely mask the initial eight digits of the Aadhaar number within an image of an Aadhaar Card.",
//     }, {
//         icon: GistIcon,
//         title: "GIST",
//         link: 'https://www.gistai.in',
//         isExternal: true,
//         description: "Your PDF AI - like ChatGPT but for PDFs. Summarize and answer questions.",
//     }, {
//         icon: CKYCIcon,
//         title: "CKYC Cropping",
//         link: '/dashboard/kyc-cropping',
//         isExternal: false,
//         description: "Remove the necessity for customers to go through repetitive KYC procedures when dealing with various financial institutions..",
//     }, {
//         icon: VideoKycIcon,
//         title: "Video KYC",
//         link: '/dashboard/vkyc',
//         isExternal: false,
//         description: "Enroll users from a distance via video, achieving a high level of confidence, with or without agent assistance, all while maintaining compliance.",
//     }, {
//         icon: VoterIdIcon,
//         title: "Voter ID Verification",
//         link: '/dashboard/ocr',
//         isExternal: false,
//         description: "Verify Indian Voter ID.",
//     }, {
//         icon: OtherIcon,
//         title: "Many More",
//         link: '/dashboard/other',
//         isExternal: false,
//         description: "Span Classifier, NSFW Detection, Name Compare, Address Match, Autocorrect, GST OCR, Active Liveness, Passive Liveness.",
//     }
// ]

type resDataType = {
  isExternal: boolean;
  desc: string;
  id: string;
  logo_url: any;
  name: string;
}[];

const Homepage = () => {
  const [resData, setResData] = useState<resDataType>([]);
  const [productData, setProductData] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    allProductsApi()
      .then((res: any) => {
        console.log("res allProductsApi ", res, "res[1]", res[1]);
        setResData([...res]);
      })
      .catch((err) => {
        console.log("res allProductsApi err ", err);
      });
  }, []);

  useEffect(() => {
    userSettingApi()
      .then((res: any) => {
        const selectedProducts = res.selected_products.split(",");

        // Filtering resData based on selected_products
        const filteredResData = resData.filter((item: any) =>
          selectedProducts.includes(item.id)
        );
        // Setting filtered data to productData
        setProductData([...filteredResData]);
      })
      .catch((err) => {
        console.log("res userSettingApi err ", err);
      });
  }, [resData]);

  document.title = "AiFise | Home";

  const userLoginData = localStorage.getItem("userLoginData");
  const isUserAdmin = userLoginData && JSON.parse(userLoginData).role === "adm";

  useEffect(() => {
    if (!isUserAdmin) {
      localStorage.setItem("prod_list", JSON.stringify(productData));
    } else {
      localStorage.setItem("prod_list", JSON.stringify(resData));
    }
  }, [isUserAdmin, productData, resData]);
  return (
    <>
      <Heading />
      <Box
        sx={{
          px: 4,
          pb: 4.7,
          display: "block",
        }}
      >
        <Box
          sx={{
            background: "#fff",
            mt: "-30px",
            // minHeight: "calc(100vh - 230px)",
            boxShadow:
              "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px -8px 17px 0px rgba(0, 0, 0, 0.10), 0px -31px 31px 0px rgba(0, 0, 0, 0.09), 0px -69px 41px 0px rgba(0, 0, 0, 0.05), 0px -123px 49px 0px rgba(0, 0, 0, 0.01), 0px -192px 54px 0px rgba(0, 0, 0, 0.00), 0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <Grid container>
            {isUserAdmin
              ? // If user is an admin, map productData
                resData?.map((item: any, index: any) => {
                  return (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <div onClick={() => navigate(`${getApiUrl(item.id)}`)}>
                        <ProductCard
                          icon={item?.logo_url}
                          title={item.name}
                          id={item.id}
                          description={item.desc}
                        />
                      </div>
                    </Grid>
                  );
                })
              : productData?.map((item: any, index: any) => {
                  console.log("first");
                  return (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <div onClick={() => navigate(`${getApiUrl(item.id)}`)}>
                        <ProductCard
                          icon={item?.logo_url}
                          title={item.name}
                          id={item.id}
                          description={item.desc}
                        />
                      </div>
                    </Grid>
                  );
                })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

const getApiUrl = (type: string) => {
  switch (type) {
    case "inno-fm":
      return `/dashboard/face-match`;
    case "inno-ocr":
      return `/dashboard/ocr`;
    case "inno-face-analysis":
      return `/dashboard/face-analysis`;
    case "inno-aadhar-masking":
      return `/dashboard/aadhar-masking`;
    case "inno-gist":
      return `/dashboard/gist`;
    case "inno-ckyc-cropping":
      return `/dashboard/ckyc`;
    case "inno-video-kyc":
      return `/dashboard/vkyc`;
    case "inno-vid-verification":
      return `/dashboard/voterid-verification`;
    case "inno-face-liveness":
      return `/dashboard/liveliness `;
    case "inno-address-match":
      return `/dashboard/address-match`;
    case "inno-sdks":
      return `/dashboard/sdk`;
    case "inno-moderation":
      return `/dashboard/violence-detection`;
    case "inno-face-search":
      return `/dashboard/face-search`;
    case "inno-spam-classifier":
      return `/dashboard/spam-classifier`;
    case "inno-aifise-onboarding":
      return `/dashboard/onboarding`;
    case "inno-image-quality-analysis":
      return "/dashboard/doc-quality";
  }
};

export default Homepage;
